/*
	import { wkDaysNums, monthNums, monthStrs, monthNumerals } from '@/utilities/Date/constants'
*/

/*
	const dayOfWeek = wkDaysNums [ moment ().day () + 1 ];

		moment.day () 
			0 -> Sunday
			1 -> Monday
			...
			6 -> Saturday
*/

export const wkDaysNums = Object.freeze({
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday'
})

export const monthNums = Object.freeze({
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
})

export const monthNumerals = Object.freeze({
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12'
})

export const monthStrs = Object.freeze({
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12
})
