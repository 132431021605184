<template>
  <Modal
    event-details
    :toggle.sync="isOpen"
    :loading="loading"
    :styles="{ modalContent: { minHeight: '400px' } }"
    recurring-event-adder
  >
    <template v-slot:header>
      <h4 :style="{ margin: '12px 0' }">{{ event.name }}</h4>
    </template>

    <div>
      <div class="has-text-weight-semibold pl-2">
        {{ formatTimespan(event.startDate, event.endDate) }}
      </div>

      <div class="pl-2 mt-4">{{ event.description }}</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import { formatTimespan } from '@/utilities/Date/formatTimespan'

export default {
  components: {
    Modal
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      event: {
        startDate: '',
        endDate: ''
      }
    }
  },
  methods: {
    formatTimespan,
    open({ event }) {
      this.loading = false
      this.isOpen = true

      this.event = event
    }
  }
}
</script>
