/*
	import { getCalendarEvents } from '@/services/CalendarEvent/Calendaring/GetCalendarEvents';

  const { result, successful, message } = await getCalendarEvents ({
    params: {
			hoaID,
      startDate,
      endDate,
      interval
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function getCalendarEvents({ params }) {
  let message = 'The community calendar events list could not be retrieved.'

  let _params = '?'
  for (let key in params) {
    _params += key + '=' + params[key] + '&'
  }

  _params.slice(0, _params.length - 1)

  try {
    const result = await kms.post(`/CalendarEvent/Calendaring/GetCalendarEvents${_params}`)

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: {}
  }
}
