export function data() {
  return {
    month: null,
    year: null,
    events: [],
    //
    isReady: false,
    //
    calendarProblem: ''
  }
}
