/*
    import { getArray } from '@/utilities/get/Array'

    const array = getArray ()
 */

import _get from 'lodash/get'

export function getArray(a, b, c) {
  try {
    const variable = _get(a, b, c)

    if (Array.isArray(variable)) {
      return variable
    }
  } catch (x) {
    console.error(x)
  }

  return []
}
