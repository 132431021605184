/*
  Example:
    import { formatTimespan } from '@/utilities/Date/formatTimespan'

    const timespanString = formatTimespan(startTime, endTime)

    startTime and endTime may be moment objects or parseable date/time strings
*/
import moment from 'moment'

export const formatTimespan = (startTime, endTime) => {
  let time1 = startTime
  let time2 = endTime
  if (typeof time1 === 'string') {
    time1 = moment(time1)
    if (!time1.isValid()) return 'Invalid start time'
  }
  if (typeof time2 === 'string') {
    time2 = moment(time2)
    if (!time2.isValid()) return 'Invalid end time'
  }

  if (time1.isSame(time2, 'day')) {
    // Same-day format
    return time1.format('LL h:mm a') + ' to ' + time2.format('h:mm a')
  } else {
    return time1.format('LL h:mm a') + ' to ' + time2.format('LL h:mm a')
  }
}
