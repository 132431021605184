/*
	import { lastDayOfMonth } from '@/utilities/Date/lastDayOfMonth'

	const { m, dayOfMonth } = lastDayOfMonth ({ year, month });

	// month (number): 1 to 12
	// year (number)
*/

import moment from 'moment'

export function lastDayOfMonth({ year, month }) {
  const m = moment(new Date(year, month, 0, 23, 59, 59))

  return {
    m,
    dayOfMonth: m.date()
  }
}
