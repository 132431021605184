<template>
  <PageContentLayoutOnly>
    <PleasantCalendar
      owner-community-calendar
      ref="calendar"
      :pageChange="calendarUpdate"
      :ready="ready"
      :dayTapped="dayTapped"
      :eventTapped="eventTapped"
      :events="events"
      :externalProblem="calendarProblem"
    />

    <EventDetails ref="eventDetails" />
  </PageContentLayoutOnly>
</template>

<script>
// import EventAdder from '../EventAdder'
// import EventDetails from '../EventDetails'
// import RecurringEventAdder from '../RecurringEventAdder'

import EventDetails from './components/EventDetails/index.vue'

import PleasantCalendar from '@/components/Calendars/Pleasant'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { computed } from './keys/computed'
import { watch } from './keys/watch'
import { mounted } from './keys/mounted'

export default {
  components: {
    PageContentLayoutOnly,
    PleasantCalendar,
    EventDetails
  },
  data,
  computed,
  methods,
  watch,
  mounted
}
</script>
